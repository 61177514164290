.shimmer{
    padding: 5px;
}

.container{ 
    padding: 5px
}

.breadcrumbWrapper{
    padding-top: 15px;
    padding-left: 35px;
}