.shimmer{
    margin: 5px;
}

.avatar{
     background-color: white !important;
}

.avatarIcon {
    color: black !important;
    font-size: 32px;
}
footer {
    padding: 24px !important;
    text-align: end;
}