.container {
    display: flex !important;
    flex-direction:column;
    justify-content: center !important;
    align-items: center !important;
    height: 100vh !important ;
    font-size: large !important;
}

.none {
    display: flex !important;
    flex-direction:column;
    justify-content: center !important;
    align-items: center !important;
    font-size: large !important;
}